import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/node_modules/uberschrift/dist/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/src/assets/arrow.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/src/assets/navRight.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/src/components/CaseStudyTeaser/CaseStudyTeaser.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["getEventForLink","trackCustomEvent"] */ "/home/runner/work/nextwww/nextwww/src/components/helpers.ts");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/src/components/Image/CmsImage.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/src/components/Navigation/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/src/components/Navigation/SubNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/AgendaSection/Agenda.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BlogPostTeaserSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/BlogPostTeaserSection/BlogPostTeaserSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CallToActionSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/CallToActionSection/CallToActionSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardGridSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/CardGridSection/CardGridSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaseStudyListSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/CaseStudyListSection/CaseStudyListSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaseStudyPageHeaderSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/CaseStudyPageHeaderSection/CaseStudyPageHeaderSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChallengeSolutionSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/ChallengeSolutionSection/ChallengeSolutionSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConsultingOfferSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/ConsultingOfferSection/ConsultingOfferSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactFormSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/ContactFormSection/ContactFormSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FactsAndFiguresSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/FactsAndFiguresSection/FactsAndFiguresSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FaqSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/FaqSection/FaqSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FourImagesSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/FourImagesSection/FourImagesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FullWidthPhotoTextSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/FullWidthPhotoTextSection/FullWidthPhotoTextSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeIntroSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/HomeIntroSection/HomeIntroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageCloudSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/ImageCloudSection/ImageCloudSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InfoBlockSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/InfoBlockSection/InfoBlockSection.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/src/components/sections/KeyTopicsSection/KeyTopicsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LandingPageHeaderSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/LandingPageHeaderSection/LandingPageHeaderSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ListOfProsSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/ListOfProsSection/ListOfProsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocationSketchSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/LocationSketchSection/LocationSketchSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoWallSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/LogoWallSection/LogoWallSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MapSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/MapSection/MapSection.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/src/components/sections/MarkdownSection/MarkdownSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MeetTheTeamSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/MeetTheTeamSection/MeetTheTeamSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MulticolumnSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/MulticolumnSection/MulticolumnSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/OverviewPageHeaderSection/ScrollAnchor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageHeaderComboSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/PageHeaderComboSection/PageHeaderComboSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageHeaderIlluSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/PageHeaderIlluSection/PageHeaderIlluSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageHeaderPhotoSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/PageHeaderPhotoSection/PageHeaderPhotoSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PipedriveFormSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/PipedriveFormSection/PipedriveFormSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PublicationListSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/PublicationListSection/PublicationListSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuoteImageSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/QuoteImageSection/QuoteImageSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ServicesSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/ServicesSection/ServicesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StickyPopupSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/StickyPopupSection/StickyPopupSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TalkListSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/TalkListSection/TalkListSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TestimonialSection"] */ "/home/runner/work/nextwww/nextwww/src/components/sections/TestimonialSection/TestimonialSection.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/src/components/sections/WorkshopsSection/WorkshopsSection.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/src/components/styled/ContentContainer.tsx");
